<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.77 47.02"
      role="img"
      aria-hidden="true"
      aria-labelledby="logo-title"
    >
      <title id="logo-title">씨네소파 로고</title>
      <path
        fill="currentColor"
        class="cls-1"
        d="M219.89,17.21a9.48,9.48,0,0,0-5.07,1.31V1.79h-5.4V35.5C207.89,38,202.3,41,198.06,
                41h0V11.57h-5.4V41l-11.6,0V11.57h-5.41V41h-6.49v5.4l29.13,0c3.79,0,8.56-1.52,
                11.13-4.2V47h5.4V25.34a5.34,5.34,0,0,1,5.07-2.73A8,8,0,0,1,223.66,
                24l3.11-4.41A13.32,
                13.32,0,0,0,219.89,17.21Z"
      />
      <rect
        fill="currentColor"
        class="cls-1"
        x="101.21"
        y="1.77"
        width="5.4"
        height="45.23"
      />
      <path
        fill="currentColor"
        class="cls-1"
        d="M137.38,19.09c1.59,3.39,4.55,6.57,8.79,9.32a49.12,49.12,0,0,0,14,
                5.46l1.14-5.28c-4.91-1.12-10.1-3.32-12.34-4.93-8.54-6.17-8.19-10.3-7.29-13.44a6.81,
                6.81,0,0,
                1,3.2-4.1,5.43,5.43,0,0,1,3.87-.56L150.63.49a10.7,10.7,0,0,0-7.2.32c-3.75,1.48-5.48,
                4.33-8.78,
                12.12-2.9,6.85-11.54,13.3-21,15.71L115,33.87C124.32,31.5,132.77,25.82,137.38,19.09Z"
      />
      <path
        fill="currentColor"
        class="cls-1"
        d="M140.57,37.41a12.74,12.74,0,0,0-2.39-8.89l-4.47,3a7.34,7.34,0,0,1,1.49,5.29c-.25,
                2.35-1,3.48-1.73,
                4.16H112.36v5.4h51.38V41H139.56A10.69,10.69,0,0,0,140.57,37.41Z"
      />
      <rect
        fill="currentColor"
        class="cls-1"
        x="48.16"
        y="1.79"
        width="5.4"
        height="45.23"
      />
      <path
        fill="currentColor"
        class="cls-1"
        d="M69.59,41H66.08V3.25h-5.4V46.44h8.91c10.28,0,17-1.18,20.56-3.6l-3-4.47C85.65,
                39.37,81.43,41,69.59,41Z"
      />
      <rect
        fill="currentColor"
        class="cls-1"
        x="169.16"
        y="3.25"
        width="36.17"
        height="5.4"
      />
      <path
        fill="currentColor"
        class="cls-1"
        d="M92.74,25.9V47h5.4V1.77h-5.4V20.34a9.51,9.51,0,0,1-2.58.66A22.68,22.68,0,0,
                1,83,20.5l-1.63,5.15a26.18,
                26.18,0,0,0,7,.85A13,13,0,0,0,92.74,25.9Z"
      />
      <path
        fill="currentColor"
        class="cls-1"
        d="M17.78,22.81a11.62,11.62,0,0,0,3.91,10.78c3.17-4.64,4.16-8.09,5.83-15.73,
                1.79-8.17,7.32-13.65,13.59-15.2l1.3,5.25C38,9,34.35,12.8,32.87,18.49a17.72,
                17.72,0,0,0,1.56,12.84c2.42,4,6.83,7.58,9.52,8.57L42.07,45A25.67,25.67,0,0,
                1,28.71,31.91,29.11,29.11,0,0,1,15.93,46.08l-2.58-4.74A23.06,23.06,0,0,0,
                18.06,38a18.54,18.54,0,0,1-4.45-6,30.58,30.58,0,0,1-10.8,11.3L0,38.63c7.38-4.49,
                10.73-11.49,13.75-21.34,1.77-5.79,4.27-10,7.43-12.47a16.22,16.22,0,0,1,
                4.11-2.38l2,5a10.78,10.78,0,0,0-2.75,1.6,14.7,14.7,0,0,0-3.3,4.15A43.75,43.75,
                0,0,0,17.78,22.81Z"
      />
    </svg>
  </div>
  <!-- <svg     xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-hidden="true"
    aria-labelledby="logo-title" viewBox="0 0 130.72 41.45">
      <title id="logo-title">씨네소파 로고</title>
    <path
      fill="currentColor"
      d="M127.9,7.08a3.85,3.85,0,0,0-2.09.54V.74h-2.22V14.6a6.57,
      6.57,0,0,1-4.67,2.24h0V4.76H116.7V16.85h-4.77V4.76H109.7v12.1H107v2.22l12,0a6.84,6.84,
      0,0,0,4.58-1.72v2h2.22V10.42A2.19,2.19,0,0,1,127.9,9.3a3.19,3.19,0,0,1,
      1.54.56L130.72,8A5.47,5.47,0,0,0,127.9,7.08Z"
    />
    <rect fill="currentColor" x="79.09" y="0.73" width="2.22" height="18.6" />
    <path
      fill="currentColor"
      d="M94,7.85a9.63,9.63,0,0,0,3.61,3.83,20.2,20.2,0,0,0,
      5.76,2.25l.47-2.17a16.63,16.63,0,0,1-5.08-2c-3.51-2.54-3.37-4.24-3-5.53a2.77,
      2.77,0,0,1,1.31-1.68,2.22,2.22,0,0,1,1.59-.24L99.42.2a4.46,4.46,0,
      0,0-3,.13c-1.54.61-2.25,1.78-3.61,5-1.19,2.81-4.74,5.47-8.64,6.46l.55,
      2.15A16.32,16.32,0,0,0,94,7.85Z"
    />
    <path
      fill="currentColor"
      d="M95.28,15.38a5.22,5.22,0,0,0-1-3.65L92.46,13a3,3,0,0,1,
      .61,2.18,2.44,2.44,0,0,1-.71,1.71H83.68v2.22h21.13V16.86h-10A4.37,4.37,0,0,
      0,95.28,15.38Z"
    />
    <rect fill="currentColor" x="57.28" y="0.74" width="2.22" height="18.6" />
    <path
      fill="currentColor"
      d="M66.09,16.88H64.65V1.34H62.43V19.1h3.66c4.23,0,7-.49,8.46-1.49L73.3,
      15.78C72.69,16.19,71,16.88,66.09,16.88Z"
    />
    <rect fill="currentColor" x="107.03" y="1.34" width="14.87" height="2.22" />
    <path
      fill="currentColor"
      d="M75.61,10.65v8.68h2.22V.73H75.61V8.36a4.08,4.08,0,0,1-1.06.28,9.47,
      9.47,0,0,1-3-.21l-.67,2.12a11,11,0,0,0,2.85.35A5.47,5.47,0,0,0,75.61,10.65Z"
    />
    <path
      fill="currentColor"
      d="M44.79,9.38a4.77,4.77,0,0,0,1.61,4.43c1.3-1.91,1.71-3.32,2.39-6.47a7.94,
      7.94,0,0,1,5.59-6.25l.53,2.16A5.71,5.71,0,0,0,51,7.6a7.25,7.25,0,0,0,.65,
      5.28,9.32,9.32,0,0,0,3.91,3.53l-.77,2.09a10.55,10.55,0,0,1-5.5-5.38A12,12,
      0,0,1,44,19L43,17a9.63,9.63,0,0,0,1.94-1.39,7.39,7.39,0,0,1-1.83-2.48,12.63,
      12.63,0,0,1-4.45,4.65l-1.15-1.9c3-1.84,4.41-4.72,5.65-8.77A10.13,10.13,0,0,
      1,46.19,2a6.53,6.53,0,0,1,1.69-1l.82,2.07a4.23,4.23,0,0,0-1.14.66,6.08,
      6.08,0,0,0-1.35,1.7A17.92,17.92,0,0,0,44.79,9.38Z"
    />
  </svg> -->
</template>

<script>
export default {};
</script>

<style>
</style>